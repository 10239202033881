import { createRouter, createWebHistory } from 'vue-router'
import moment from "moment";
import { auth } from "@/firebase";
import { onAuthStateChanged } from 'firebase/auth'

import HomeView from '../views/HomeView.vue'
import LayoutView from '../layouts/LayoutView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/panel',
    meta: { requiresAuth: true },
    component: LayoutView,
    children: [
      {
        path: '/about',
        name: 'about',
        meta: { admin: true },
        component: () => import('../views/AboutView.vue')
      },
      {
        path: '/company',
        name: 'company',
        meta: { admin: true },
        component: () => import('../views/CompanyView.vue')
      }
    ]
  },
  {
    path: '/:catchAll(.*)',
    name: 'not-found',
    redirect: to => {
      return { path: to.redirectedFrom || '/about' };
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);

  try {

    const userAuth = await new Promise((resolve, reject) => {
      onAuthStateChanged(auth, (user) => {
        if (user) {
          user.getIdTokenResult().then(idTokenResult => {
            user.customClaims = idTokenResult.claims;
            resolve(user);
          }).catch(error => {
            reject(error);
          });
        } else {
          resolve(null);
        }
      });
    });

    if (userAuth) {
      const { admin } = userAuth.customClaims;
      const { expirationTime } = userAuth.stsTokenManager;
      const expiration = moment() < moment(expirationTime);

      if (admin && expiration) {
        if (to.matched.some(record => record.meta.admin)) {
          next();
        } else {
          next({ name: 'about' })
          // next(false);
        }
      } else {
        await auth.signOut();
        next({ name: "home", query: { redirect: '99' } });
      }

    } else {
      if (requiresAuth) {
        next({ name: "home" });
      } else {
        next();
      }
    }
  } catch (error) {
    console.error("Error durante la autenticación:", error);
    next();
  }
})

export default router
