import moment from 'moment'
import { initializeApp } from 'firebase/app';
import { getAuth, connectAuthEmulator } from "firebase/auth";
import { getFirestore, connectFirestoreEmulator, collection, getDocs, doc, getDoc, updateDoc, setDoc } from "firebase/firestore"
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";

const firebaseConfig = {
    apiKey: "AIzaSyCl1cVBUMIB0azlhXi_JJtb84UxNtO14jY",
    authDomain: "relevent-d2924.firebaseapp.com",
    projectId: "relevent-d2924",
    storageBucket: "relevent-d2924.appspot.com",
    messagingSenderId: "83896155465",
    appId: "1:83896155465:web:c468e0a16fecab4710ee98",
    measurementId: "G-82RTPT6VPQ"
};

const app = initializeApp(firebaseConfig);

const auth = getAuth(app);
const db = getFirestore();
const functions = getFunctions();

if (process.env.NODE_ENV === 'development_') {
    connectAuthEmulator(auth, "http://localhost:9099");
    connectFunctionsEmulator(functions, "localhost", 5001);
    connectFirestoreEmulator(db, 'localhost', 8081);
}

export { auth, db, functions }

// Functions CREATE
async function createPlan(companyId, currentYear) {
    const docRef = doc(db, "companies", companyId, "subscriptions", String(currentYear));
    const months = [{ enero: false }, { febrero: false }, { marzo: false }, { abril: false }, { mayo: false }, { junio: false }, { julio: false }, { agosto: false }, { septiembre: false }, { octubre: false }, { noviembre: false }, { diciembre: false }];
    await setDoc(docRef, { months: months });
}

// Functions GET
async function getCompanies() {
    const items = [];
    const querySnapshot = await getDocs(collection(db, "companies"));
    querySnapshot.forEach((doc) => {
        items.push({ uid: doc.id, ...doc.data() })
    });
    return items;
}

async function getCompany(params) {
    const docRef = doc(db, "companies", params);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
        const data = docSnap.data();
        const docRef2 = doc(db, "companies", params, "subscriptions", String(moment().year()));
        const docSnap2 = await getDoc(docRef2);

        return { ...data, ...docSnap2.data() };
    } else {
        throw new Error("No such document!");
    }

}

// Functions UPDATE
async function updateState(id, field, state) {

    const update = field ? 'disabled' : 'warning';

    const companyRef = doc(db, "companies", id);
    await updateDoc(companyRef, { [update]: state });
}

async function updatePay(id, data, years) {
    const docRef = doc(db, "companies", id, "subscriptions", String(years));
    await updateDoc(docRef, { months: data });
}

export { createPlan, getCompanies, getCompany, updateState, updatePay }