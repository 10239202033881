<template>
    <v-app id="inspire">

        <v-app-bar color="black" flat>
            <div class="mx-5">
                <img height="21" src="https://app.relevent.cl/img/logo_dark.e9fe3e60.png" alt="Relevent">
            </div>
            <v-spacer />
            <v-list-item :subtitle="currentUser.email" :title="currentUser.displayName">
                <template v-slot:append>
                    <v-btn icon="mdi-logout-variant" variant="text" color="grey" class="ml-3" @click="onLogout" />
                </template>
            </v-list-item>
        </v-app-bar>

        <v-main class="bg-grey-lighten-4">
            <v-container>
                <router-view />
            </v-container>
        </v-main>
    </v-app>
</template>

<script>
import { mapState } from "vuex";
import { auth } from "@/firebase";
import { signOut } from "firebase/auth";

export default {
    data: () => ({}),

    computed: {
        ...mapState(['currentUser'])
    },

    methods: {
        async onLogout() {
            if (confirm("Seguro que deseas salir?")) {
                await signOut(auth).then(() => {
                    this.$router.push({ name: 'home' })
                }).catch((error) => {
                    console.log('An error happened', error)
                });
            }
        }
    },
}
</script>