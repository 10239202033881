import { createStore } from 'vuex'

export default createStore({
  state: {
    currentUser: null
  },
  getters: {
  },
  mutations: {
    SET_CURRENTUSER(state, payload) { state.currentUser = payload }
  },
  actions: {
  },
  modules: {
  }
})
