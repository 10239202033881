<template>
  <div class="home text-center">
    <v-row>
      <v-col cols="9" md="4">
        <v-form ref="form" @submit.prevent>
          <v-text-field v-model="email" label="Email" variant="solo-filled" type="email" autocomplete flat />
          <v-text-field v-model="password" label="Contraseña" variant="solo-filled" type="password" autocomplete flat />
          <v-btn color="primary" :disabled="loading" :loading="loading" @click="onSubmit">Ingresar</v-btn>
        </v-form>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { auth } from "@/firebase";
import { signInWithEmailAndPassword } from "firebase/auth";

export default {
  name: 'HomeView',

  data: () => ({
    email: '',
    password: '',
    loading: false,
  }),

  methods: {
    async onSubmit() {
      this.loading = true;
      await signInWithEmailAndPassword(auth, this.email, this.password)
        .then(() => {
          this.$router.push({ name: 'about' })
        })
        .catch((error) => {
          const errorMessage = error.message;
          console.log(errorMessage)
        });

      this.loading = false;
    }
  },
}
</script>
