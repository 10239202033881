import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { auth } from "./firebase"
import { onAuthStateChanged } from "firebase/auth"

let app

onAuthStateChanged(auth, async (user) => {
    if (user) {
        const userData = { displayName: user.displayName, email: user.email }
        store.commit('SET_CURRENTUSER', userData);
    }

    if (!app) {
        app = createApp(App).use(store).use(router).use(vuetify).mount('#app')
    }
})